export default function Party() {
    return (
        <div>
            <div className="jv-banner3 team-banner flex-col">
                <h2>Register for the <span className="yellow-text">End Of Year Party</span></h2>
                <div className="yellow-bar"></div>
            </div>
            <div className="partyPage">
                <iframe className=""
                    src="https://lu.ma/embed/event/evt-0DayjaOPyuieudf/simple"
                    height="600"
                    frameborder="0"
                    style={{
                        border: '1px solid #bfcbda88',
                        borderRadius: '4px'
                    }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                    title="party"
                ></iframe>
            </div >
        </div>
    )
}