import React from 'react';
import './Marquee.css';

const multiplyImages = (images, count) => {
    let clonedImages = [];
    for (let i = 0; i < count; i++) {
      clonedImages = [...clonedImages, ...images];
    }
    return clonedImages;
  };

const Marquee = () => {
    const images = [
        '/img/IMG_8946.png',
        '/img/IMG_8947.png',
        '/img/blockchain.png',
        '/img/Diamund.png',
        '/img/edo-innov.png',
        '/img/gdg.png',
        '/img/victor.png',
        '/img/w.png'
        // Add more image URLs as needed
    ];

    const clonedImages = multiplyImages(images, 200);

    return (
        <div className="marquee-container">
            <div className="marquee-content">
                {clonedImages.map((image, index) => (
                    <div key={index} className="image-container">
                        <img src={image} alt={`img ${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Marquee;
