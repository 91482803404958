import SubstackFeed from './SubstackFeed';
function Blog() {
  return (
    <div className="blog">
      <div className='sixth-section'>
        <h3>Our Latest Posts</h3>
        <div className='stroke'></div>
      </div>
      <div className='allPages'>
        <SubstackFeed />
      </div>

    </div>

  )
}

export default Blog;