import { useState } from "react"
import "./joinVolunteer.css"
import "./resources.css"
import "./searchResource.css"

import SearchIcon from "../assets/icons/uil_search.png"
import { ResourceCard } from "../components/ResourceCard"

import img1 from "../assets/images/Global business internet network connection iot internet of things business intelligence concept busines global network futuristic technology background ai generative.png"
import img2 from "../assets/images/Cloud networking with people files dropping through mobile phones.png"
import img3 from "../assets/images/Revenue operations concept.png"

export default function Resources() {

    const [searchInput, setSearchInput] = useState("");


    const [resources, setResources] = useState([
        {
            title: "Edo Angel Investors List",
            img: img1,
            link: "#"
        },
        {
            title: "Edo Start Up List",
            img: img2,
            link: "#"
        },
        {
            title: "Edo Tech Communities",
            img: img3,
            link: "#"
        },
        {
            title: "Online Hubs",
            img: img3,
            link: "#"
        },
        {
            title: "Events",
            img: img2,
            link: "#"
        },
        {
            title: "Internship Opportunities",
            img: img1,
            link: "#"
        },
    ])

    const [searchResults, setSearchResults] = useState(resources)

    const searchList = (text) => {
        if (!text) {
            setSearchResults(resources)
        }
        setSearchResults(resources.filter(el => el.title.toLowerCase().includes(text)))
    }



    return (
        <div className="resources join-volunteer flex-col">
            <div className={`jv-banner r-banner flex-col ${searchInput && "banner-searching"}`}>
                <div className={`flex-col sub-resource ${searchInput && "searching"}`}>
                    <h2>Discover the Edo Tech <span className="yellow-text">Ecosystem.</span></h2>
                    <p>Search through the database of the tech ecosystem</p>
                </div>
                <div className="flex-col search">
                    <img src={SearchIcon} alt="Search Icon" />
                    <input type="text"
                        value={searchInput}
                        placeholder="Search here"
                        // className="search-input"
                        onChange={(e) => {
                            setSearchInput(e.target.value.toLowerCase())
                            searchList(e.target.value.toLowerCase())
                            window.scrollTo(0, 0)
                        }}
                    />
                </div>
            </div>
            <div className="resource-list">
                {
                    searchResults.length ?
                        searchResults.map(resource => {
                            return (
                                <ResourceCard
                                    title={resource.title}
                                    link={resource.link}
                                    img={resource.img}
                                />
                            )
                        }) : <p className="sorry flex-col">Sorry, your search query did not match any item in our database.😢 </p>
                }
            </div>

        </div>
    )
}
