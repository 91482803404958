import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import overlayImg from "../assets/images/Overlay.png";
import img from "../assets/images/Global business internet network connection iot internet of things business intelligence concept busines global network futuristic technology background ai generative.png"
import {RingLoader} from "react-spinners"


const SubstackFeed = () => {
  const [posts, setPosts] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSubstackPosts = async () => {
      try {
        const response = await fetch('https://etc-o41b.onrender.com/substack-feed');
        const data = await response.json();
        setPosts(data.items);
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching Substack posts:', error);
      }
    };

    fetchSubstackPosts();
  }, []);

  // Check if there is at least one post
  const latestPost = posts.length > 0 ? posts[0] : null;
  const olderPosts = posts.slice(1, 4); // Exclude the latest post

  return (
    <div>
      <div className='second-section'>
        {/* Display the latest post in the 'updateBanner' section */}
        {latestPost && (
          <div className='updateBanner'>
            <div className='updateImage'>
              {/* You can uncomment the following line if you have an image for the latest post */}
              {/* <img src={latestPost.image.url} alt='update img' /> */}
              <div className='updateText'>
                <div className='updates'>
                  <h3>What's New</h3>
                  <div className='stroke2'></div>
                </div>
                <h2>{latestPost.title}</h2>
                <p>{latestPost.description}</p>
                <a href={latestPost.link} target="_blank" rel="noopener noreferrer" className="btn1">
                  Read More...
                </a>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Display older posts in the 'details' section */}
      
      {
        !isLoading ?
      <div className='allPages2'>
        <div className='cards'>
          {olderPosts.map((post) => (
            <Link to={post.link} className="search-card" key={post.link} target="_blank" rel="noopener noreferrer">
              <div className="overlay">
                {/* Assuming 'img' and 'overlayImg' are defined somewhere */}
                {/* <img src={overlayImg} alt="background overlay" />  */}
              </div>
              <div className="details">
                <h2 className="black-text">{post.title}</h2>
                <p>{post.description}</p>
                <a href={post.link} target="_blank" rel="noopener noreferrer" className="btn1">
                  Read More...
                </a>
              </div>
            </Link>
          ))}
        </div>
      </div> :(
              <div className="flex-col loader-cont ">
                <h3 className="">Loading blogs<span className="">...</span></h3>
               <RingLoader color="purple" />
             </div>
             )
      }
    </div>
  );
};

export default SubstackFeed;
