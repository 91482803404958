import placeholder from "../assets/images/Property 1=Variant4(2).png"
import InstaIcon from "../assets/icons/insta-black.png"
import TwitterIcon from "../assets/icons/tweet-black.png"
import LinkedIcon from "../assets/icons/link-black.png"
import webLinkIcon from "../assets/icons/link.png"


const MemberCard = ({ name, position, img, linkedin, website }) => {
    return (
        <div className="member-card flex-col">
            <img
                src={img} alt=""
                className="member-image"
            />
            <h2>{name}</h2>
            <p>{position}</p>
            <div className="member-links">
                {/* <a href="http://twitter.com/tech_in_edo" target="_blank" rel="noreferrer"><img src={TwitterIcon} className="member-link" alt="twitter icon" height="40px"></img></a> */}
                <a href={linkedin} target="_blank" rel="noreferrer"><img src={LinkedIcon} alt="Linked In icon" height="25px"></img></a>
                <a href={website} target="_blank" rel="noreferrer"><img src={webLinkIcon} alt="weblink icon" height="25px"></img></a>
            </div>
        </div>
    )
}

export default MemberCard