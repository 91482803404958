import overlayImg from "../assets/images/Overlay.png"
export const ResourceCard = ({ title, img }) => {
    return (
        <a href="#" className="search-card">
            <div className="overlay">
                <img src={overlayImg} alt="background overlay" />
            </div>
            <img src={img} alt="" />
            <div className="details">
                <h2>{title}</h2>
              
            </div>
        </a>
    )
}
