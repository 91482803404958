import React from 'react';
import './Gallery.css';
import etc1 from "../assets/images/etc1_1_11zon.webp";
import etc2 from "../assets/images/etc2_2_11zon.webp";
import etc3 from "../assets/images/etc3_3_11zon.webp";
import etc4 from "../assets/images/etc4_4_11zon.webp";
import etc5 from "../assets/images/etc5_5_11zon.webp";
// import etc6 from "../assets/images/etc6_6_11zon.webp";
// import etc7 from "../assets/images/etc7_7_11zon.webp";
// import etc8 from "../assets/images/etc8_8_11zon.webp";
// import etc9 from "../assets/images/etc9_9_11zon.webp";
// import etc10 from "../assets/images/etc10.webp";

const Gallery = () => {
    // Array of image objects with path and alt text
    const images = [
        { path: `${etc1}` },
        { path: `${etc2}` },
        { path: `${etc3}` },
        { path: `${etc4}` },
        { path: `${etc5}` },
        // { path: `${etc6}` },
        // { path: `${etc7}` },
        // { path: `${etc8}` },
        // { path: `${etc9}` },
        // { path: `${etc10}` },
        // Add more images as needed
    ];

    const getRandomImage = () => {
        const randomIndex = Math.floor(Math.random() * images.length);
        return images[randomIndex];
    };

    const generateImageArray = () => {
        const imageArray = [];
        for (let i = 0; i < 10; i++) {
            imageArray.push(getRandomImage());
        }
        return imageArray;
    };

    return (
        <div className='gallery'>
            <h3>Explore Our World</h3>
            <div className='stroke2'></div>
            <div className="gallery-container">
                <div className="gallery-content">
                    {Array.from({ length: 200 }, (_, index) => (
                        <a href="http://instagram.com/tech_in_edo" target="_blank"  rel="noreferrer" key={index} className={index % 2 === 0 ? "gallery-image-container" : "gallery-image-container2"}>
                            {index % 2 === 0 ? (
                                <img className="gallery-image" src={getRandomImage().path} alt={getRandomImage().alt} />
                            ) : (
                                <>
                                    <div className='top-img'><img className="gallery-image" src={getRandomImage().path} alt={getRandomImage().alt} /></div>
                                    <div className='down-img'><img className="gallery-image" src={getRandomImage().path} alt={getRandomImage().alt} /></div>
                                </>
                            )}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Gallery;
