import Gallery from "../components/Gallery";


function About() {
    return (
        <div className='fourth-section'>
            <div className="jv-banner2 team-banner flex-col">
                <h2>Who <span className="yellow-text">We Are</span></h2>
                <div className="yellow-bar"></div>
            </div>
            <div className='new2'>
                {/* <h3>About Us</h3>
                <div className='stroke2'></div> */}
                <div className='allPages'>
                    <p> <span className='space'></span> The <span className="color2 bold"> Edo Tech Community</span> exists to drive the growth of a united tech ecosystem in Edo State.
                        The <br /> 
                        idea to build the Edo Tech Community came in December 2022
                        when the founding members met to <br /> 
                        discuss the need to bring the
                        <span className='color2 bold'> Edo Tech ecosystem</span> closer together. But we didn’t want to
                        start another <br />
                         “tech community” we wanted to build the
                        bridge between existing ones. We had noticed how <br />
                        fragmented the ecosystem was and wanted to do
                        what it will take to make stakeholders relate better <br />
                        with each other. In January 2023, our journey began. </p>
                        <p> <span className='space'></span>
                        Today, that idea has led to our core purpose <span className='color2 bold'>-telling
                        the Edo Tech story</span>. Through key partnerships <br /> between
                        home-based and foreign stakeholders, we are driving
                        growth in the ecosystem, celebrating <br /> builders and
                        innovators, and telling the stories from the <span className="color2 bold">Edo tech scene</span> to the world.
                        </p>
                       
                </div>
            </div>



            <div className='allPages'>
                <div className='mission'>
                    {/* <img src={oneIcon} alt="icon" className="numbers img" /> */}
                    <img src='/img/mission.png' alt='mission img' />
                    <div className='missionText'>
                        <div className='text1'>
                            <div className='numbers'><img src='/img/01.png' alt="icon" className="" /></div>
                            <p>Create and promote opportunities <br /> for enthusiasts to enter and build a <br /> career in tech.</p>
                        </div>
                        <div className='text3'>
                            <div className='numbers'><img src='/img/03.png' alt="icon" className="" /></div>
                            <p>Showcase Edo tech to the <br /> world through captivating <br /> stories.</p>
                        </div>
                        <div className='text2'>
                            <div className='numbers'> <img src='/img/02.png' alt="icon" className="" /></div>
                            <p>Drive growth and collaboration <br /> in the Edo tech ecosystem <br /> through partnerships and <br /> events.</p>
                        </div>
                    </div>
                </div>
                            <div className='mission2'>
                    {/* <img src={oneIcon} alt="icon" className="numbers img" /> */}
                    <img src='/img/mission.png' alt='mission img' />
                    <div className='missionText'>
                        <div className='text1'>
                            <div className='numbers'><img src='/img/01.png' alt="icon" className="" /></div>
                            <p>Create and promote opportunities <br /> for enthusiasts to enter and build a <br /> career in tech.</p>
                        </div>
                        <div className='text2'>
                            <div className='numbers'> <img src='/img/02.png' alt="icon" className="" /></div>
                            <p>Drive growth and collaboration <br /> in the Edo tech ecosystem <br /> through partnerships and <br /> events.</p>
                        </div>
                            <div className='text3'>
                            <div className='numbers'><img src='/img/03.png' alt="icon" className="" /></div>
                            <p>Showcase Edo tech to the <br /> world through captivating <br /> stories.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Gallery />
        </div>



    )
}

export default About;


