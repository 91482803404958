import { Link } from 'react-router-dom'
function Weare() {
    return (
        <div className='fourth-section'>
            <div className='new'>
                <h3>Partner With Us</h3>
                <div className='stroke'></div>
                <p>Want to learn more or partner with us? <br /> <br />
                </p>
                <Link to="/about" className="btn3">Go Here</Link>
            </div>

        </div>

    )
}

export default Weare;


