import InstaIcon from "../assets/icons/basil_instagram-solid.png"
import TwitterIcon from "../assets/icons/uil_twitter.png"
import LinkedIcon from "../assets/icons/bi_linkedin.png"
import EdoTechIcon from "../assets/icons/Artboard 17 copy 14 1.png"
import TikTokIcon from "../assets/icons/Vector.png"


const Footer = () => {

    return (
        <footer>
            <div className="allPages">
                <img src={EdoTechIcon} className='logo2' alt="edo tech community icon"></img>
                <div className="icons">
                    <p>hello@edotech.com.ng</p>
                    <div className="icon-list">
                        <a href="http://twitter.com/tech_in_edo" target="_blank" rel="noreferrer"><img src={TwitterIcon} alt="twitter icon" height="24p/x"></img></a>
                        <a href="http://linkedin.com/company/edotechcommunity" target="_blank" rel="noreferrer"><img src={LinkedIcon} alt="Linked In icon" height="24p/x"></img></a>
                        <a href="http://instagram.com/tech_in_edo" target="_blank" rel="noreferrer"><img src={InstaIcon} alt="Instagram icon" height="24p/x"></img></a>
                        <a href="https://tiktok.com/@tech_in_edo" target="_blank" rel="noreferrer"><img src={TikTokIcon} alt="Tiktok icon" height="24p/x"></img></a>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer