import { Link } from 'react-router-dom'
import Marquee from '../components/Marquee';
import Weare from './weAre';
import Gallery from '../components/Gallery';
// import overlayImg from "../assets/images/Overlay.png"
// import img from "../assets/images/Global business internet network connection iot internet of things business intelligence concept busines global network futuristic technology background ai generative.png"
import SubstackFeed from './SubstackFeed';

// import placeholder from "../assets/images/IMG_8946.png"
function Home() {
    // const images = [
    //     '../assets/images/IMG_8946.png',
    //     '../assets/images/IMG_8947.png',
    //     '../assets/images/IMG_8948.png',
    //     '../assets/images/IMG_8949.png',
    //     // Add more image URLs as needed
    // ];
    return (
        <div>
            <div className="banner">
                <div className="allPages">
                    <div className="bannerInfo">
                        <h1>Unite. Build. <br />
                            Showcase. <span className='edo'>Edo</span></h1>
                        {/* <p className="intro">Into the eco-system of our dreams.</p> */}
                        <div className="stroke"></div>
                        <Link to="/join" className="btn3">Join Us</Link>
                    </div>
                </div>
            </div>

            {/* Supported By */}
            <div className='third-section'>
                <h2>PROUDLY SUPPORTED BY</h2>
                <div>
                    <Marquee />
                </div>
            </div>

           <SubstackFeed />
            {/* About Us */}

            <div className='fourth-section'>
                <div>

                    <Weare />


                    {/* Mission */}
        

                </div>
            </div>

            <div className='fifth-section'>
                <div>
                    <h3>Let's Talk</h3>
                    <div className='stroke'></div>
                    <p>Want to partner with us or make enquiries? <br />
                        Send an email to</p>
                    <h3 className='hello'>hello@edotech.com.ng</h3>
                </div>

            </div>

            <Gallery />
            
            <div className='third-section'>
                <h2>Connect With The Community</h2>
                <div className='stroke2'></div>
                <div className='icons2'>
                    <a href="http://twitter.com/tech_in_edo" target="_blank" rel="noreferrer"><img src='/img/uil_twitter.png' alt="twitter icon" height="24p/x"></img></a>
                    <a href="http://linkedin.com/company/edotechcommunity" target="_blank" rel="noreferrer"><img src='/img/bi_linkedin.png' alt="Linked In icon" height="24p/x"></img></a>
                    <a href="http://instagram.com/tech_in_edo" target="_blank" rel="noreferrer"><img src='/img/ri_instagram-fill.png' alt="Instagram icon" height="24p/x"></img></a>
                    <a href="https://tiktok.com/@tech_in_edo" target="_blank" rel="noreferrer"><img src='/img/simple-icons_tiktok.png' alt="Tiktok icon" height="24p/x"></img></a>
                </div>
            </div>
        </div>
    )
}

export default Home;
