import { useEffect, useState } from "react";
import {RingLoader} from "react-spinners"
export default function Partner(){
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '27244747',
                    formId: 'c58f69e7-e255-4d96-a049-ebf4ba383c65',
                    target: '#hubspotForm'
                })

                setTimeout(() => {
                    setIsLoading(false)

                }, 1000)
            }
        });
    }, []);
    return (
        <div className="join-volunteer ">
            <div className="jv-banner flex-col">
                
                <h2 className="">Come Be Our <span className="yellow-text">Partner</span></h2>
                <p>Join the movement to build a greater Edo tech ecosystem</p>
                <div className="yellow-bar"></div>
                <a href="#hubspotForm" className="btn-join flex-col">Join Us</a>
            
            </div>

            {
                isLoading ? 
                <div className="flex-col">
                    <p>Please wait a moment...</p>
                    {/*
                        <ThreeCircles
                            visible={true}
                            height="100"
                            width="100"
                            color="blue"
                        />
                     */}
                    <RingLoader color="purple" />
                </div>
                 : ""
            }

            <div id="hubspotForm" className="form-card"></div>

        </div>
    )
}